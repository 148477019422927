import {
    Card,
    CardButton,
    CardTitle,
} from '../components';

const ErrorElement = () => (
    <div className='w-2/3 m-auto'>
        <Card>
            <CardTitle>Ha ocurrido un error inesperado</CardTitle>
            <CardButton position='bottom' linkTo='/'>Vovler a inicio</CardButton>
            <div>Lo sentimos. Favor vuelva a inicio, e intente de nuevo.</div>
        </Card>
    </div>
);

export default ErrorElement;
