import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";

export type ButtonProps = {
    linkTo?: string;
    children: string;
    icon?: IconProp;
    onClick?: () => void;
    dataTestId?: string;
}

const Button = ({ linkTo, children, icon, onClick, dataTestId }: ButtonProps) => {
    return (
        <Link to={linkTo ?? ''}>
            <button data-test-id={dataTestId ?? ''} onClick={onClick} className='flex p-2 m-2 text-sm text-white transition ease-in-out delay-75 bg-black rounded shadow-md hover:-translate-y-1 hover:scale-110 active:bg-slate-500'>
                {icon ? <div className='ml-2'><FontAwesomeIcon icon={icon} /> </div> : null}
                <span className='px-2 m-auto'>{children}</span>
            </button>
        </Link>
    )
}

export default Button;
