type SelectOption = {
    id: string;
    name: string;
} | string

type SelectInputProps = {
    id: string;
    label: string;
    default?: SelectOption;
    options: SelectOption[];
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    dataTestId?: string;
}

const SelectInput = ({ id, label, options, onChange, dataTestId }: SelectInputProps) => {
    return (
        <div className='flex flex-col w-1/3 p-2'>
            <label htmlFor={id} className='font-light text-slate-600'>{label}</label>
            <select data-test-id={dataTestId} id={id} name={label} onChange={onChange} className='p-2 border rounded focus:outline-black'>
                {options.map(option => <option value={typeof option === 'string' ? option : option.id}>{typeof option === 'string' ? option : option.name}</option>)}
            </select>
        </div>
    )
}

export default SelectInput;
