import {
    Bar,
    BarChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Cell,
    LabelList,
} from 'recharts';

import { Card, CardDescription, CardTitle } from '../components';
import type { RaceOdds, Statistic } from '../model';

type RaceChartProps = {
    raceOdds: RaceOdds
}

type OrderItem = {
    name: string,
    advantage: string,
    score: number,
    color: string,
}

const RaceChart = ({ raceOdds }: RaceChartProps) => {
    const order = getOrder(raceOdds.stats);

    return (
        <Card>
            <CardTitle>{`Carrera #${raceOdds.analysis.race.number}`}</CardTitle>
            <CardDescription>El orden en que calculamos que llegarán los ejemplares, y con cuantos cuerpos de ventaja; de acuerdo a nuestros calculos.</CardDescription>
            <div style={{ height: 400, width: '100%' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart width={150} height={40} data={order} layout="vertical">
                        <XAxis
                            type="number"
                            axisLine={false}
                            tickLine={false}
                            tickMargin={500}
                        />
                        <YAxis
                            yAxisId={0}
                            dataKey="name"
                            type="category"
                            axisLine={false}
                            tickLine={false}
                            width={250}
                        />
                        <Bar dataKey="score" name="Cuerpos" barSize={32} radius={[0, 10, 10, 0]}>
                            <LabelList
                                dataKey='advantage'
                                fill='#000000'
                                position='right'
                                width={800}
                            />
                            {order.map((d) => {
                                return <Cell key={d["score"]} stroke='#000000' fill={d.color} />;
                            })}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Card>
    )
}

export {
    RaceChart
}

const getOrder = (stats: Statistic[]): OrderItem[] => stats.map((s, i) => ({
    name: `(${placeMetaData[i].name}) Ej #${s.horse.number} ${s.horse.name}`,
    advantage: `${getBodiesDiff(s, i, stats)}`,
    score: s.score.body_based_position_score,
    color: placeMetaData[s.horse.number - 1].color
}));

const getBodiesDiff = (stat: Statistic, index: number, stats: Statistic[]) => {
    if (index === stats.length - 1) {
        return 'Pierde';
    }

    const diff = Math.ceil((stat.score.body_based_position_score - stats[index + 1].score.body_based_position_score) * 10) / 10
    if (index === 0) {
        return `${diff} cuerpos`
    }

    return `${diff} cuerpos`
}

const placeMetaData = [
    {
        name: "1er",
        color: "#FF0000",
    },
    {
        name: "2do",
        color: "#ffffff",
    },
    {
        name: "3er",
        color: "#0000FF",
    },
    {
        name: "4to",
        color: "#FFFF00",
    },
    {
        name: "5to",
        color: "#00FF00",
    },
    {
        name: "6to",
        color: "#000000",
    },
    {
        name: "7mo",
        color: "#DF5800",
    },
    {
        name: "8vo",
        color: "#9F2B68",
    },
    {
        name: "9no",
        color: "#006666",
    },
    {
        name: "10mo",
        color: "#964B00",
    },
    {
        name: "11er",
        color: "#808080",
    },
    {
        name: "12do",
        color: "#F38F00",
    }
]
