import { useContext, useReducer, createContext } from "react";
import type { PropsWithChildren, Reducer, Dispatch } from 'react';

import type { Horse, PastRace } from '../model';

type UpdateHorseName = {
    type: 'update-horse-name';
    name: string;
}

type UpdateHorseNumber = {
    type: 'update-horse-number';
    number: number;
}

type UpdateHorseTrade = {
    type: 'update-horse-trade';
    trade: 'imported' | 'native';
}

type AddPastRace = {
    type: 'add-past-race';
    pastRace: PastRace;
}

type DeletePastRace = {
    type: 'delete-past-race';
    index: number;
}

type UpdatePastRace = {
    type: 'update-past-race';
    index: number;
    pastRace: PastRace;
}

type UpdateJockeyWinsCount = {
    type: 'update-jockey-wins-count';
    winsCount: number;
}

type UpdateJockeyWeightLbs = {
    type: 'update-jockey-weight-lbs';
    weightLbs: number;
}

type SetHorse = {
    type: 'set-horse';
    horse: Horse;
}

type HorseAction = UpdateHorseName
    | UpdateHorseNumber
    | UpdateHorseTrade
    | AddPastRace
    | DeletePastRace
    | UpdatePastRace
    | UpdateJockeyWinsCount
    | UpdateJockeyWeightLbs
    | SetHorse

type HorseContextType = {
    horse: Horse;
    dispatch: Dispatch<HorseAction>
} | undefined

const HorseContext = createContext<HorseContextType>(undefined);

const initialHorse: Horse = {
    name: '',
    number: 0,
    trade: 'native',
    jockey: {
        winsCount: 0,
        weightLbs: 0,
    },
    pastRaces: []
}

const HorseProvider = ({ children }: PropsWithChildren) => {
    const [horse, dispatch] = useReducer<Reducer<Horse, HorseAction>>(horseReducer, initialHorse);

    return (
        <HorseContext.Provider
            value={{
                horse, dispatch
            }}>
            {children}
        </HorseContext.Provider>
    )
}

const horseReducer = (horse: Horse, action: HorseAction) => {
    switch (action.type) {
        case 'update-horse-name': {
            return { ...horse, name: action.name };
        }
        case 'update-horse-trade': {
            return { ...horse, trade: action.trade };
        }
        case 'update-horse-number': {
            return { ...horse, number: action.number };
        }
        case 'add-past-race': {
            return { ...horse, pastRaces: [...horse.pastRaces, action.pastRace] };
        }
        case 'delete-past-race': {
            const pastRaces = [...horse.pastRaces];
            pastRaces.splice(action.index, 1);
            return { ...horse, pastRaces }
        }
        case 'update-past-race': {
            const pastRaces = [...horse.pastRaces];
            pastRaces.splice(action.index, 1, { ...action.pastRace });
            return { ...horse, pastRaces }
        }
        case 'update-jockey-wins-count': {
            return { ...horse, jockey: { ...horse.jockey, winsCount: action.winsCount } };
        }
        case 'update-jockey-weight-lbs': {
            return { ...horse, jockey: { ...horse.jockey, weightLbs: action.weightLbs } };
        }
        case 'set-horse': {
            return { ...action.horse };
        }
        default: {
            throw Error(`Unknown action: ${JSON.stringify(action)}`);
        }
    }
}

const useHorse = () => {
    const context = useContext(HorseContext);
    if (context === undefined) {
        throw new Error('useHorse must be used within an HorseContext');
    }
    return context;
}

export {
    HorseProvider,
    useHorse,
    initialHorse,
}
