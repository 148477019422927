import { useReducer, createContext, useContext } from 'react';
import type { PropsWithChildren, Reducer, Dispatch } from 'react';

import type { Analysis, Horse } from '../model';

type UpdateRaceDate = {
    type: 'update-race-date';
    date: Date;
}

type UpdateRaceNumber = {
    type: 'update-race-number';
    number: number;
}

type UpdateRaceTrack = {
    type: 'update-race-track';
    trackName: string;
}

type UpdateRaceDistance = {
    type: 'update-race-distance';
    distanceMeters: number;
}

type AddHorse = {
    type: 'add-horse';
    horse: Horse;
}

type DeleteHorse = {
    type: 'delete-horse';
    index: number;
}

type UpdateHorse = {
    type: 'update-horse';
    index: number;
    horse: Horse;
}

type SetAnalysis = {
    type: 'set-analysis';
    analysis: Analysis;
}

type AnalysisAction = UpdateRaceDate
    | UpdateRaceNumber
    | UpdateRaceTrack
    | UpdateRaceDistance
    | AddHorse
    | DeleteHorse
    | UpdateHorse
    | SetAnalysis

type AnalysisContextType = {
    analysis: Analysis;
    dispatch: Dispatch<AnalysisAction>
} | undefined

const initialAnalysis = {
    race: {
        date: new Date(),
        number: 0,
        trackName: 'Camarero',
        distanceMeters: 0
    },
    horses: []
}

const AnalysisContext = createContext<AnalysisContextType>(undefined);

const AnalysisProvider = ({ children }: PropsWithChildren) => {
    const [analysis, dispatch] = useReducer<Reducer<Analysis, AnalysisAction>>(analysisReducer, initialAnalysis);

    return (
        <AnalysisContext.Provider
            value={{ analysis, dispatch }}>
            {children}
        </AnalysisContext.Provider>
    )
}

const analysisReducer = (analysis: Analysis, action: AnalysisAction) => {
    switch (action.type) {
        case 'update-race-date': {
            return { ...analysis, race: { ...analysis.race, date: action.date } };
        }
        case 'update-race-number': {
            return { ...analysis, race: { ...analysis.race, number: action.number } };
        }
        case 'update-race-track': {
            return { ...analysis, race: { ...analysis.race, trackName: action.trackName } };
        }
        case 'update-race-distance': {
            return { ...analysis, race: { ...analysis.race, distanceMeters: action.distanceMeters } }
        }
        case 'add-horse': {
            return { ...analysis, horses: [...analysis.horses, action.horse] };
        }
        case 'delete-horse': {
            const horses = [...analysis.horses];
            horses.splice(action.index, 1);
            return { ...analysis, horses };
        }
        case 'update-horse': {
            const horses = [...analysis.horses];
            horses.splice(action.index, 1, { ...action.horse });
            return { ...analysis, horses };
        }
        case 'set-analysis': {
            return { ...action.analysis }
        }
        default: {
            throw Error(`Unkown action: ${action}`);
        }
    }
}

const useAnalysis = () => {
    const context = useContext(AnalysisContext);
    if (context === undefined) {
        throw new Error('useAnalysis must be used within an AnalysisProvider');
    }
    return context;
}

export {
    AnalysisProvider,
    useAnalysis,
    initialAnalysis,
}
