import { useCallback } from "react"

const useEnv = () => {

    const getEnv = useCallback((key: string) => {
        return process.env[key];
    }, []);

    return {
        analyizerApi: getEnv('REACT_APP_API_BASE_URL') ?? ''
    }
}

export {
    useEnv
}
