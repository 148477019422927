import { useCallback, useState } from "react";

import { useEnv } from "./useEnv"
import type { Analysis, RaceOdds } from "../model";
import { serializeAnalysis } from "../util";

type BaseResponse = {
    response: string;
    message: string;
}

type GetAllResponse = {
    analysisList: Analysis[];
} & BaseResponse;

type GetByIdResponse = {
    analysis: Analysis | null;
} & BaseResponse;

type GetReportByAnalysisId = {
} & RaceOdds & BaseResponse;

type GetReportByDate = {
    raceOdds: RaceOdds[]
} & BaseResponse

const useApi = () => {

    const { analyizerApi } = useEnv();
    const [loading, setLoading] = useState<Boolean>(false);
    const [error, setError] = useState<String | null>(null);

    const parseAnalysis = (a: Analysis): Analysis => {
        a.race.date = new Date(a.race.date);
        a.horses.map(h => h.pastRaces.map(pr => {
            pr.date = new Date(pr.date)
            return pr;
        }))
        return a;
    }

    const uploadAnalysis = useCallback(async (analysis: Analysis) => {
        try {
            setLoading(true);
            console.log(serializeAnalysis(analysis));
            await fetch(`${analyizerApi}/api/analysis`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: serializeAnalysis(analysis),
                }
            );
            setLoading(false);
        } catch (error) {
            setError('Error inesperado al subir analysis. Intente de nuevo mas tarde');
            setLoading(false);
        }
    }, [analyizerApi]);

    const getAllAnalysis = useCallback(async (): Promise<Analysis[]> => {
        try {
            setLoading(true);
            const response = await fetch(`${analyizerApi}/api/analysis`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const json = await response.json() as GetAllResponse;
            setLoading(false);
            if (!response.ok) {
                setError('Error inesperado al obtener los analysis guardados. Contacte a soporte tecnico');
            }
            return json.analysisList.map(a => parseAnalysis(a));
        } catch (error) {
            setError('Error inesperado al obtener los analysis guardados. Intente de nuevo mas tarde');
            setLoading(false);
            return []
        }
    }, [analyizerApi]);

    const getAnalysisById = useCallback(async (id: string): Promise<Analysis | null> => {
        try {
            setLoading(true);
            const response = await fetch(`${analyizerApi}/api/analysis/${id}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const json = await response.json() as GetByIdResponse;
            setLoading(false);
            if (json.analysis != null && response.status === 200) {
                return parseAnalysis(json.analysis);
            } else if (response.status === 404) {
                setError(`No se pudo encontrar el análisis con ID ${id}`);
                return null;
            } else {
                setError(`Error inesperado. Favor contacte soporte tecnico`);
                return null
            }
        } catch (error) {
            setError(`Error inesperado. Favor intente de nuevo mas tarde`);
            return null;
        }
    }, [analyizerApi]);

    const getStatisticsByAnalysisId = useCallback(async (analyisisId: string | undefined) => {
        if (analyisisId === undefined) {
            setError("Analysis no especificado");
            return null;
        }
        try {
            setLoading(true);
            const response = await fetch(`${analyizerApi}/api/report?analysisId=${analyisisId}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const json = await response.json() as GetReportByAnalysisId;
            setLoading(false);
            if (json.stats != null && response.status === 200) {
                json.analysis.race.date = new Date(json.analysis.race.date);
                return json;
            } else if (response.status === 404) {
                setError(`No se pudo encontrar el análisis con ID ${analyisisId}`);
                return null;
            } else {
                setError(`Error inesperado. Favor contacte soporte tecnico`);
                return null
            }
        } catch (error) {
            setError(`Error inesperado. Favor intente de nuevo mas tarde`);
            return null;
        }
    }, [analyizerApi]);

    const getStatisticsByDate = useCallback(async (date: Date) => {
        try {
            setLoading(true);
            const response = await fetch(`${analyizerApi}/api/report?date=${date.toISOString()}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const json = await response.json() as GetReportByDate;
            setLoading(false);
            if (json.raceOdds != null && response.status === 200) {
                json.raceOdds.map(odds => odds.analysis.race.date = new Date(odds.analysis.race.date));
                return json;
            } else if (response.status === 404) {
                setError(`No se pudo encontrar el reporte para el dia ${date}`);
                return null;
            } else {
                setError(`Error inesperado. Favor contacte soporte tecnico`);
                return null
            }
        } catch (error) {
            setError(`Error inesperado. Favor intente de nuevo mas tarde`);
            return null;
        }
    }, [analyizerApi]);

    return {
        getAllAnalysis,
        uploadAnalysis,
        getAnalysisById,
        getStatisticsByAnalysisId,
        getStatisticsByDate,
        loading,
        error
    }
}

export {
    useApi
}
