type NumberInputProps = {
    id: string;
    label: string;
    value?: number;
    placeHolder?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    dataTestId?: string;
    step?: number
}

const NumberInput = ({ id, label, value, step, onChange, dataTestId, placeHolder }: NumberInputProps) => {
    return (
        <div className='flex flex-col w-1/4 p-2'>
            <label htmlFor={id} className='font-light text-slate-600'>{label}</label>
            <input data-test-id={dataTestId} id={id} name={label} onChange={onChange} placeholder={placeHolder} defaultValue={value} type='number' step={step ?? 1 } className='p-2 border rounded focus:outline-black' />
        </div>
    )
}

export default NumberInput;
