import { useEffect, useState } from 'react'
import { ThreeDot } from 'react-loading-indicators';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Card,
    CardTitle,
    CardButton,
    Button,
    CardTable,
    DateInput,
} from '../components';
import { useApi } from '../hooks';
import type { Analysis, RaceOdds } from '../model';
import { formatDate } from '../util';
import { useAnalysis } from '../providers';
import { initialAnalysis } from '../providers/AnalysisProvider';

const Home = () => {
    const { date } = useParams();
    const { dispatch } = useAnalysis();
    const [raceOddsList, setRaceOddsList] = useState<RaceOdds[]>([])
    const { getStatisticsByDate, loading, error } = useApi();
    const navigate = useNavigate();

    const editAnalysis = (analysis: Analysis) => {
        dispatch({ type: 'set-analysis', analysis: analysis });
        navigate('/analysis');
    }

    const newAnalysis = () => {
        dispatch({ type: 'set-analysis', analysis: initialAnalysis });
        navigate('/analysis');
    }

    useEffect(() => {
        if (!date) {
            return;
        }

        const fetchRaceOdds = async () => {
            const statsByDateResponse = await getStatisticsByDate(new Date(date));
            if (statsByDateResponse) {
                setRaceOddsList(statsByDateResponse.raceOdds)
            }
        }

        fetchRaceOdds();
    }, [date])

    const searchAnalysis = async (date: Date) => {
        navigate(`/${date.toISOString()}`);
    }

    return (
        <div className='w-2/3 m-auto'>
            <Card>
                <CardTitle>Buscador de Análisis</CardTitle>
                <DateInput id="analysis-search" label="Búsqueda de análisis" value={date ? new Date(date) : new Date()} onChange={(e) => searchAnalysis(new Date(e.target.value))} />
                <CardButton position='top' onClick={newAnalysis} icon='plus-circle' dataTestId='new-analysis'>Nuevo Análisis</CardButton>
                {(loading && <div className='w-full m-auto'><ThreeDot variant='pulsate' color='#000000' size='large' /></div>)
                    || error && <div>Ha ocurrido un error {error}</div>
                    || raceOddsList.length === 0 && <div>No se encontraron análisis para esta fecha</div>
                    || <>
                        <CardTable
                            items={raceOddsList}
                            columns={[
                                { header: 'Fecha', getter: (raceOdds: RaceOdds) => formatDate(raceOdds.analysis.race.date) },
                                { header: 'Hipódromo', getter: (raceOdds: RaceOdds) => raceOdds.analysis.race.trackName },
                                { header: 'Carrera', getter: (raceOdds: RaceOdds) => raceOdds.analysis.race.number },
                                { header: 'Distancia', getter: (raceOdds: RaceOdds) => raceOdds.analysis.race.distanceMeters },
                                { header: 'Ejemplares', getter: (raceOdds: RaceOdds) => raceOdds.analysis.horses.length },
                                { header: '', getter: (raceOdds: RaceOdds) => <Button onClick={() => editAnalysis(raceOdds.analysis)} icon='pen-to-square'>Editar Carrera</Button> },
                            ]}
                        />
                        <Button linkTo={`/report/${date}`} icon='chart-simple'>Ver Reporte</Button>
                    </>
                }
            </Card>
        </div>
    );
};

export default Home;
