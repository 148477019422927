import { useNavigate } from 'react-router-dom';
import { ThreeDot } from 'react-loading-indicators'

import {
    Card,
    CardButton,
    Button,
    CardDescription,
    CardTable,
    CardTitle,
    DateInput,
    NumberInput,
    SelectInput
} from '../components';
import type { Horse } from '../model';
import { useApi } from '../hooks';
import { useHorse, useAnalysis, initialHorse } from '../providers';

const AnalysisForm = () => {
    const tracks = ['Camarero']
    const navigate = useNavigate();
    const { analysis, dispatch } = useAnalysis();
    const horseContext = useHorse();
    const { uploadAnalysis, loading, error } = useApi();

    const updateRaceDate = (date: Date) => {
        dispatch({ type: 'update-race-date', date });
    }

    const updateRaceNumber = (number: number) => {
        dispatch({ type: 'update-race-number', number });
    }

    const updateRaceDistance = (distanceMeters: number) => {
        dispatch({ type: 'update-race-distance', distanceMeters });
    }

    const updateRaceTrack = (trackName: string) => {
        dispatch({ type: 'update-race-track', trackName });
    }

    const newHorse = () => {
        horseContext.dispatch({ type: 'set-horse', horse: initialHorse });
        navigate('/horse');
    }

    const deleteHorse = (index: number) => {
        dispatch({ type: 'delete-horse', index });
    }

    const editHorse = (horse: Horse) => {
        horseContext.dispatch({ type: 'set-horse', horse: horse });
        navigate(`/horse/${analysis.horses.indexOf(horse)}`);
    }

    const submit = () => {
        uploadAnalysis(analysis);
        navigate(`/${analysis.race.date.toISOString()}`);
    }

    return (
        <div className='w-2/3 m-auto'>
            {
                error ?
                    <Card>
                        <CardTitle>Ha ocurrido un error</CardTitle>
                        <div>{error ?? 'Favor vuelva a inicio, e intente de nuevo.'}</div>
                    </Card>
                    : loading ?
                        <Card>
                            <CardTitle>Cargando</CardTitle>
                            <ThreeDot variant='pulsate' color='#000000' size='large' />
                        </Card>
                        :
                        <div>
                            <Card>
                                <CardTitle>Detalles de la carrera</CardTitle>
                                <CardDescription>Fecha, hipodromo y número de carrera</CardDescription>
                                <div className='flex'>
                                    <DateInput dataTestId='race-date' id='race-date' label='Fecha' value={analysis.race.date} onChange={(e) => updateRaceDate(new Date(e.target.value))} />
                                    <SelectInput dataTestId='race-track' id='race-track' label='Hipódromo' options={tracks} default={analysis.race.trackName} onChange={(e) => updateRaceTrack(e.target.value)} />
                                    <NumberInput dataTestId='race-number' id='race-number' label='Número' value={analysis.race.number} onChange={(e) => updateRaceNumber(parseInt(e.target.value))} />
                                    <NumberInput dataTestId='race-distance' id='race-distance' label='Distancia (Metros)' value={analysis.race.distanceMeters} onChange={(e) => updateRaceDistance(parseInt(e.target.value))} />
                                </div>
                            </Card>
                            <Card >
                                <CardTitle>Ejemplares</CardTitle>
                                <CardDescription>Agregue o remueva los ejemplares de la carrera</CardDescription>
                                <CardButton dataTestId='new-horse' position='top' onClick={newHorse} icon='plus-circle'>Agregar Ejemplar</CardButton>
                                <CardTable
                                    items={analysis.horses}
                                    columns={[
                                        { header: 'Número', getter: (horse: Horse) => horse.number },
                                        { header: 'Nombre', getter: (horse: Horse) => horse.name },
                                        { header: 'Importe', getter: (horse: Horse) => horse.trade === 'imported' ? 'Importado' : 'Nativo' },
                                        { header: '', getter: (horse: Horse) => <Button onClick={() => editHorse(horse)} icon='pen-to-square'>Editar</Button> },
                                        { header: '', getter: (horse: Horse) => <Button onClick={() => deleteHorse(analysis.horses.indexOf(horse))} icon='trash'>Borrar</Button> },
                                    ]}
                                />
                            </Card>
                            <Button dataTestId='analyze' onClick={submit} icon='floppy-disk'>Guardar Análisis</Button>
                        </div>
            }
        </div>
    );
};

export default AnalysisForm;
