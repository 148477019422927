import { useNavigate, useParams } from 'react-router-dom';

import {
    Card,
    CardButton,
    CardDescription,
    CardTable,
    CardTitle,
    NumberInput,
    TextInput,
    Button
} from "../components";
import { useAnalysis, useHorse } from '../providers';
import type { PastRace } from '../model';
import { formatDate } from "../util";
import { initialPastRace, usePastRace } from '../providers/PastRaceProvider';

const HorseForm = () => {
    const navigate = useNavigate();
    const { index } = useParams();
    const { horse, dispatch } = useHorse();
    const analysisContext = useAnalysis();
    const pastRaceContext = usePastRace();

    const updateHorseName = (name: string) => {
        dispatch({ type: 'update-horse-name', name });
    }

    const updateHorseNumber = (number: number) => {
        dispatch({ type: 'update-horse-number', number });
    }

    const updateJockeyWinsCount = (winsCount: number) => {
        dispatch({ type: 'update-jockey-wins-count', winsCount });
    }

    const updateJockeyWeightLbs = (weightLbs: number) => {
        dispatch({ type: 'update-jockey-weight-lbs', weightLbs });
    }

    const editPastRace = (pastRace: PastRace) => {
        pastRaceContext.dispatch({ type: 'set-past-race', pastRace: pastRace });
        navigate(`/past-race/${horse.pastRaces.indexOf(pastRace)}`);
    }

    const deletePastRace = (index: number) => {
        dispatch({ type: 'delete-past-race', index });
    }

    const newPastRace = () => {
        pastRaceContext.dispatch({ type: 'set-past-race', pastRace: initialPastRace });
        navigate('/past-race');
    }

    const submit = () => {
        if (index) {
            analysisContext.dispatch({ type: 'update-horse', index: parseInt(index), horse });
        } else if (horse) {
            analysisContext.dispatch({ type: 'add-horse', horse });
        }
        navigate(-1);
    }

    return (
        <div className='w-2/3 m-auto'>
            <Card>
                <CardTitle>Ejemplar</CardTitle>
                <CardDescription>Datos del ejemplar</CardDescription>
                <div className='flex'>
                    <TextInput dataTestId='horse-name' id='horse-name' label='Nombre' value={horse.name} onChange={(e) => updateHorseName(e.target.value)} />
                    <NumberInput dataTestId='horse-number' id='horse-number' label='Número' value={horse.number} onChange={(e) => updateHorseNumber(parseInt(e.target.value))} />
                </div>
            </Card>

            <Card>
                <CardTitle>Jockey</CardTitle>
                <CardDescription>Datos del Jockey</CardDescription>
                <div className='flex'>
                    <NumberInput dataTestId='jockey-wins' id='jockey-wins' label='Ganadas' value={horse.jockey.winsCount} onChange={(e) => updateJockeyWinsCount(parseInt(e.target.value))} />
                    <NumberInput dataTestId='jockey-weight' id='jockey-weight' label='Peso (lbs)' value={horse.jockey.weightLbs} onChange={(e) => updateJockeyWeightLbs(parseInt(e.target.value))} />
                </div>
            </Card>

            <Card>
                <CardTitle>Carreras Pasadas</CardTitle>
                <CardDescription>Carreras pasadas mas recientes del ejemplar</CardDescription>
                <CardButton dataTestId='new-past-race' position='top' onClick={newPastRace} icon='plus-circle'>Agregar Carrera</CardButton>
                <CardTable
                    items={horse.pastRaces}
                    columns={[
                        { header: 'Fecha', getter: (race: PastRace) => formatDate(new Date(race.date)) },
                        { header: 'Distancia (metros)', getter: (race: PastRace) => race.distanceMeters },
                        { header: 'Tiempo (segundos)', getter: (race: PastRace) => race.timeSeconds },
                        { header: 'Velocidad (metros/segundo)', getter: (race: PastRace) => race.speedMetersPerSecond },
                        { header: '', getter: (race: PastRace) => <Button onClick={() => editPastRace(race)} icon='pen-to-square'>Editar</Button> },
                        { header: '', getter: (race: PastRace) => <Button onClick={() => deletePastRace(horse.pastRaces.indexOf(race))} icon='trash'>Borrar</Button> },
                    ]}
                />
            </Card>
            <Button dataTestId='save-horse' onClick={submit} icon='floppy-disk'>Guardar Ejemplar</Button>
        </div >
    )
}

export default HorseForm;
