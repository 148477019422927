import { useNavigate, useParams } from 'react-router-dom';

import {
    Card,
    Button,
    CardDescription,
    CardTitle,
    DateInput,
    NumberInput
} from '../components'
import { useHorse, usePastRace } from '../providers';

const PastRaceForm = () => {
    const navigate = useNavigate();
    const { index } = useParams();
    const horseContext = useHorse();
    const { pastRace, dispatch } = usePastRace();

    const updateDate = (date: Date) => {
        dispatch({ type: 'update-date', date });
    }

    const updateDistance = (distanceMeters: number) => {
        dispatch({ type: 'update-distance', distanceMeters });
    }

    const updateTime = (timeSeconds: number) => {
        dispatch({ type: 'update-time', timeSeconds });
    }

    const updateSpeed = (speedMetersPerSecond: number) => {
        dispatch({ type: 'update-speed', speedMetersPerSecond });
    }

    const submit = () => {
        if (index) {
            horseContext.dispatch({ type: 'update-past-race', index: parseInt(index), pastRace });
        } else {
            horseContext.dispatch({ type: 'add-past-race', pastRace });
        }
        navigate(-1);
    }

    return (
        <div className='w-2/3 m-auto'>
            <Card>
                <CardTitle>Carrera Pasada</CardTitle>
                <CardDescription>Agregue o modifique una carrera pasada</CardDescription>
                <DateInput dataTestId='past-race-date' id='past-race-date' label='Fecha' value={new Date(pastRace.date)} onChange={(e) => updateDate(new Date(e.target.value))} />
                <NumberInput dataTestId='past-race-distance' id='past-race-distance' label='Distancia (metros)' value={pastRace.distanceMeters} onChange={(e) => updateDistance(parseInt(e.target.value))} />
                <NumberInput dataTestId='past-race-time' step={0.01} id='past-race-time' label='Tiempo (segundos)' value={pastRace.timeSeconds} onChange={(e) => updateTime(parseFloat(e.target.value))} />
                <NumberInput dataTestId='past-race-speed' step={0.01} id='past-race-speed' label='Velocidad (metros/segundo)' value={pastRace.speedMetersPerSecond} onChange={(e) => updateSpeed(parseFloat(e.target.value))} />
            </Card>
            <Button dataTestId='save-past-race' icon='floppy-disk' onClick={submit}>Guardar Pasada</Button>
        </div>
    )
}

export default PastRaceForm;
