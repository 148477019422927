import { useContext, useReducer, createContext } from "react";
import type { PropsWithChildren, Reducer, Dispatch } from 'react';

import type { PastRace } from '../model';

type UpdateDate = {
    type: 'update-date';
    date: Date;
}

type UpdateDistance = {
    type: 'update-distance';
    distanceMeters: number;
}

type UpdateTime = {
    type: 'update-time';
    timeSeconds: number;
}

type UpdateSpeed = {
    type: 'update-speed';
    speedMetersPerSecond: number;
}

type SetPastRace = {
    type: 'set-past-race';
    pastRace: PastRace;
}

type PastRaceAction = UpdateDate
    | UpdateDistance
    | UpdateTime
    | UpdateSpeed
    | SetPastRace

type PastRaceContextType = {
    pastRace: PastRace;
    dispatch: Dispatch<PastRaceAction>
} | undefined

const PastRaceContext = createContext<PastRaceContextType>(undefined);

const initialPastRace: PastRace = {
    date: new Date(),
    distanceMeters: 0,
    timeSeconds: 0,
    speedMetersPerSecond: 0,
}

const PastRaceProvider = ({ children }: PropsWithChildren) => {
    const [pastRace, dispatch] = useReducer<Reducer<PastRace, PastRaceAction>>(pastRaceReducer, initialPastRace);

    return (
        <PastRaceContext.Provider
            value={{
                pastRace, dispatch
            }}>
            {children}
        </PastRaceContext.Provider>
    )
}

const pastRaceReducer = (pastRace: PastRace, action: PastRaceAction) => {
    switch (action.type) {
        case 'update-date': {
            return { ...pastRace, date: action.date };
        }
        case 'update-distance': {
            return { ...pastRace, distanceMeters: action.distanceMeters };
        }
        case 'update-time': {
            return { ...pastRace, timeSeconds: action.timeSeconds };
        }
        case 'update-speed': {
            return { ...pastRace, speedMetersPerSecond: action.speedMetersPerSecond };
        }
        case 'set-past-race': {
            return { ...action.pastRace };
        }
        default: {
            throw Error(`Unknown action: ${JSON.stringify(action)}`);
        }
    }
}

const usePastRace = () => {
    const context = useContext(PastRaceContext);
    if (context === undefined) {
        throw new Error('usePastRace must be used within an PastRaceContext');
    }
    return context;
}

export {
    PastRaceProvider,
    usePastRace,
    initialPastRace,
}
