import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ThreeDot } from 'react-loading-indicators'

import { useApi } from '../hooks';
import type { RaceOdds } from '../model';
import { Card, CardTitle, CardDescription } from '../components'
import { RaceChart } from '../components/RaceChart';
import { formatDate } from '../util';

const AnalysisReport = () => {

    const [raceOdds, setRaceOdds] = useState<RaceOdds[] | undefined>();
    const [error, setError] = useState<string | undefined>();
    const { getStatisticsByDate, loading } = useApi();
    const { date } = useParams();

    useEffect(() => {
        if (!date) {
            setError("La fecha de analysis no ha sido especificada. Vuelva a inicio e intente de nuevo");
            return;
        }
        console.log(date);
        getStatisticsByDate(new Date(date)).then(response => {
            if (!response) {
                setError("Ocurrió un problema de comunicación con el servidor. Intente de nuevo mas tarde.")
                return;
            }
            setRaceOdds(response.raceOdds);
        });
    }, [getStatisticsByDate])

    if (error) {
        return (
            <div className='w-2/3 m-auto'>
                <Card>
                    <CardTitle>Error</CardTitle>
                    <CardDescription>{error}</CardDescription>
                </Card>
            </div>
        )
    }

    if (!raceOdds || raceOdds.length === 0) {
        return (
            <div className='w-2/3 m-auto'>
                <Card>
                    <CardTitle>No Data</CardTitle>
                    <CardDescription>No se ha encontrado información para el dia {date ?? ""}</CardDescription>
                </Card>
            </div>
        )
    }

    return (
        <div className='w-2/3 m-auto'>
            <Card>
                <CardTitle>Reporte Hipico</CardTitle>
                <CardDescription>Hipodromo Camarero | {date ? formatDate(new Date(date)) : ""}</CardDescription>
            </Card>
            {(loading && <div className='w-full m-auto'><ThreeDot variant='pulsate' color='#000000' size='large' /></div>)
                || <div>
                    {raceOdds?.map((odds, index) => <RaceChart key={`race-chart-${index}-${odds.analysis.race.date.toISOString()}`} raceOdds={odds} />)}
                </div>
            }
        </div>
    )
}

export default AnalysisReport;

