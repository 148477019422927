import Button from './Button';
import type { ButtonProps } from './Button';

type CardButtonProps = {
    position: 'top' | 'bottom';
} & ButtonProps;

const CardButton = ({ children, linkTo, icon, dataTestId, onClick }: CardButtonProps) => (
    <Button linkTo={linkTo} icon={icon} dataTestId={dataTestId} onClick={onClick}>{children}</Button>
);

export default CardButton;
