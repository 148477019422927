import type { MouseEventHandler, ReactElement } from "react";

import { generateChildKey } from "../util";

type CardTableColumn = {
    header: string,
    getter: (item: any) => string | number | ReactElement;
};

type CardTableProps = {
    items: Array<any>;
    columns: CardTableColumn[];
    onRowClick?: MouseEventHandler<HTMLTableRowElement>
};

const CardTable = ({ items, columns }: CardTableProps) => (
    <div className='p-2'>
        <table className='w-full text-left rounded table-auto'>
            <thead>
                <tr className='h-16'>
                    {columns.map(({ header }) =>
                        <th key={generateChildKey()} className='font-light text-slate-600'>{header}</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {items.map((item) =>
                    <tr key={generateChildKey()} className='h-16 hover:bg-slate-100 border-y hover:cursor-pointer'>
                        {columns.map(({ getter }) =>
                            <td key={generateChildKey()}>{getter(item) ?? 'N/A'}</td>
                        )}
                    </tr>
                )}
            </tbody>
        </table>
    </div >
);

export default CardTable;
