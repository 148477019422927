import type { Analysis } from "../model";

/**
 * To format date objects as a human readable string
 * @param date - Date to show as human readable
 * @returns Human readable string representing the date
 */
export function formatDate(date: Date) {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

/**
 * To format date objects as value for the DateInput's value attr
 * @param date - The date to have as a value
 * @returns string that can be used as the value for the 'value' attr in DateInput component
 */
export function inputFormatDate(date: Date) {
    return date.toISOString().split('T')[0];
}

/**
 * To uniquely identify child components dynamically generated in a list.
 * This is required/advised of React's internal workings
 * @returns Randomly generated string
 */
export function generateChildKey() {
    return Math.random().toString(36).substring(2, 7);
}

/**
 * To serialize Analysis object
 * @returns JSON string of Analysis objec, parseable by backend
 */
export function serializeAnalysis(analysis: Analysis) {
    // @ts-ignore unused key
    return JSON.stringify(analysis, (key, value) =>
        key === 'date' ? new Date(value).toISOString() : value
    );
}
