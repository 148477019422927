import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'

import {
  Home,
  AnalysisForm,
  HorseForm,
  AnalysisReport,
  PastRaceForm,
  ErrorElement,
} from './views';
import Layout from './Layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartSimple, faFloppyDisk, faPenToSquare, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorElement/>,
    children: [
      { 
        index: true,
        path: '/:date?',
        element: <Home /> 
      },
      {
        path: '/analysis',
        element: <AnalysisForm />
      },
      {
        path: '/horse/:index?',
        element: <HorseForm />
      },
      {
        path: '/past-race/:index?',
        element: <PastRaceForm />
      },
      {
        path: '/report/:date',
        element: <AnalysisReport />
      }
    ]
  },
]);

library.add(faPlusCircle, faChartSimple, faFloppyDisk, faPenToSquare, faTrash)

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
