import { inputFormatDate } from "../util"

type DateInputProps = {
    id: string;
    label: string;
    value: Date;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    dataTestId?: string;
}

const DateInput = ({ id, label, value, onChange, dataTestId }: DateInputProps) => {

    return (
        <div className='flex flex-col w-1/3 p-2'>
            <label htmlFor={id} className='font-light text-slate-600'>{label}</label>
            <input data-test-id={dataTestId} id={id} name={label} onChange={onChange} value={inputFormatDate(value)} type='date' className='p-2 border rounded focus:outline-black' />
        </div>
    )
}

export default DateInput;
