type TextInputProps = {
    id: string;
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    dataTestId?: string;
}

const TextInput = ({ id, label, value, onChange, dataTestId }: TextInputProps) => (
    <div className='flex flex-col w-1/4 p-2'>
        <label htmlFor={id} className='font-light text-slate-600'>{label}</label>
        <input data-test-id={dataTestId} id={id} name={label} onChange={onChange} defaultValue={value} type='text' className='p-2 border rounded focus:outline-black [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none' />
    </div>
)

export default TextInput;
