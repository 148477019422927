import {
    CardDescription,
    CardTitle,
    CardButton
} from '.';

type CardProps = {
    children: JSX.Element | JSX.Element[];
    className?: string;
};

const Card = ({ children }: CardProps) => {

    if (!Array.isArray(children)) {
        children = [children];
    }

    return (
        <div className={`bg-white rounded-md shadow-lg p-8 w-full h-full my-6`}>
            <div className='flex py-0 my-0'>
                {children.find(({ type }) => type === CardTitle)}
                <div className='ml-auto'>
                    {children.find(({ type, props }) => type === CardButton && props.position === 'top')}
                </div>
            </div>
            <div className='p-2'>
                {children.find(({ type }) => type === CardDescription)}
            </div>
            {children.filter(({ type }) => type !== CardButton && type !== CardTitle && type !== CardDescription)}
            <div className='p-2 ml-auto'>
                {children.find(({ type, props }) => type === CardButton && props.position === 'bottom')}
            </div>
        </div>
    );
};

export default Card;
